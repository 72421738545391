import React, { useEffect, createRef } from 'react';
import lottie from 'lottie-web';
import { getLangFromPath } from 'src/utils/index.js';
import { AnimationContainer, Background, MapWrapper } from './styles';
import animation from './data.json';

const CipherWarAnimation = () => {
  const animationContainer = createRef();
  const lang = getLangFromPath();
  const map = lang ? require(`src/assets/images/chapter_4/sub_10/desktop/map_${lang}.svg`) : require('src/assets/images/chapter_4/sub_10/desktop/map_PL.svg');

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      // path: '/assets/json/chapter_1/sub_3/data.json',
      animationData: animation,
    });
    setTimeout(() => { anim.play(); }, 3000);
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <MapWrapper>
      <Background
        background={map}
      />
      <AnimationContainer ref={animationContainer} />
    </MapWrapper>
  );
};

export default CipherWarAnimation;
