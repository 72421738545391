import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Comb from 'src/components/desktop/comb/comb.jsx';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => {
  const images = useStaticQuery(graphql`
      query {
        foreground: file(relativePath: { eq: "chapter_4/sub_10/comb.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        background: file(relativePath: { eq: "chapter_4/sub_10/combBG.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      `);

  return (
    <SlideLarge>
      <Comb
        texts={query.all.body}
        combTitle={query.combTitle.body}
        arrowTitle={query.combTitle.frontmatter.arrow}
        images={images}
      />
    </SlideLarge>

  );
};


export default Slide;
