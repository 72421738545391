import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player'
import RightColContent from 'src/components/desktop/rightColContent/rightColContent';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import audio1 from 'src/assets/audio/chapter_4/sub_10/noise2.mp3';
import audio2 from 'src/assets/audio/chapter_4/sub_10/rus2.mp3';
import audio3 from 'src/assets/audio/chapter_4/sub_10/bipbip2.mp3';
import {
  RadioContainer, RadioBG, RadioBGContainer, ContactRadioContainer, ICanNotThingOfOtherName,
  RadioWindow, RadioWindowPositioner, RightShield, LeftShield, SwitchContainer, Switch2Container, ArrowRadio,
  ArrowRadioRight, SwitchCircle, SwitchCircleDot, RedDot, AllSiteMask, ToolTip, RadioStationSignal,
  RadioFirstViewMask
} from './styles';
import { useInView } from 'react-intersection-observer';
import ArrowLeft from './arrows/strzalka_lewo.svg';
import ArrowRight from './arrows/strzalka_prawo.svg';

const Radio = ({
  bgPhoto, radio, leftWindow, rightWindow, shield, mainSwitch, mainSwitch2, arrow, allSiteMask, query
}) => {

  const [transformRotate, setTransformRotate] = useState(0);
  const [showMask, setShowMask] = useState(true);
  const [radioSound, setRadioSound] = useState(audio1);
  const [audioPlay, setAudioPlay] = useState(false);
  const [initialPlay, setInitialPlay] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [rotateDings, setRotateDings] = useState(0);
  const [radioText, setRadioText] = useState(query.toolTips.frontmatter.noise);

  const [ref, inView] = useInView({
    threshold: 0.8,
  });

  useEffect(() => {
    if (!inView && audioPlay) setAudioPlay(false);;
    if (inView && initialPlay) setAudioPlay(true);
  }, [inView]);

  const setRotation = (deg) => {
    closeMask();
    if (showTooltip) {
      setShowTooltip(false);
    }
    if (transformRotate + deg === 360 || transformRotate + deg === -360) {
      setTransformRotate(0);
    } else {
      setTransformRotate(transformRotate + deg);
    }

    if (transformRotate + deg === 180 || transformRotate + deg === -180) {
      setRadioSound(audio2);
      if (rotateDings === 90) {
        setRadioSound(audio3);
        setRadioText(query.toolTips.frontmatter.bibleQuote);
      } else {
        setRadioSound(audio2);
        setRadioText(query.toolTips.frontmatter.noise);
      }
    } else {
      setRadioSound(audio1);
      setRadioText(query.toolTips.frontmatter.noise);
    }
  }

  const rotateDingsOnClick = () => {
    closeMask();
    if (showTooltip) {
      setShowTooltip(false);
    }
    if (transformRotate === 180 || transformRotate === -180) {
      if (rotateDings === 0) {
        setRadioSound(audio3);
        setRadioText(query.toolTips.frontmatter.bibleQuote);
      } else {
        setRadioSound(audio2);
        setRadioText(query.toolTips.frontmatter.noise);
      }
    } else {
      setRadioText(query.toolTips.frontmatter.noise);
    }
    if (rotateDings === 90) {
      setRotateDings(0);
    } else {
      setRotateDings(90);
    }
  }

  const closeMask = () => {
    if (showMask) {
      setAudioPlay(true);
      setShowMask(false);
      setInitialPlay(true);
    }
  }
  return (
    <>
      <RadioContainer ref={ref}>
        <RadioBGContainer backgroundImage={bgPhoto}>

          <AllSiteMask backgroundImage={allSiteMask}>
          </AllSiteMask>
          <RadioBG>
            <ICanNotThingOfOtherName>

              <RadioWindowPositioner isLeft>
                <RadioWindow backgroundImage={leftWindow}
                ></RadioWindow>
                <ArrowRadio backgroundImage={arrow}>
                </ArrowRadio>
              </RadioWindowPositioner>

              <RadioWindowPositioner isRight>
                <RadioWindow backgroundImage={rightWindow}
                ></RadioWindow>
                <ArrowRadioRight backgroundImage={arrow}>
                </ArrowRadioRight>
              </RadioWindowPositioner>

              <RightShield backgroundImage={shield} style={{ transform: `rotate(${transformRotate}deg)` }}>
              </RightShield>
              <LeftShield backgroundImage={shield} ></LeftShield>
              <ContactRadioContainer backgroundImage={radio}>

              </ContactRadioContainer>
              <ToolTip style={{ opacity: !showTooltip ? '0' : '1' }}>
                <MDXProvider components={{
                  p: (props) => <p {...props} tabIndex="0" />,
                }}
                >
                  <MDXRenderer>{query.toolTips.body}</MDXRenderer>
                </MDXProvider>
              </ToolTip>
              <SwitchContainer backgroundImage={mainSwitch} style={{ transform: `rotate(${transformRotate}deg)` }}>
                <SwitchCircle>
                  <SwitchCircleDot></SwitchCircleDot>
                </SwitchCircle>
              </SwitchContainer>

              <Switch2Container backgroundImage={mainSwitch2} style={{ transform: `rotate(${rotateDings}deg)` }}>
              </Switch2Container>
              <RedDot style={{ left: '62.5%', top: '66%', transform: 'rotate(-130deg)' }} onClick={() => { setRotation(-18) }} arrow={ArrowLeft}>
                <p style={{ width: '4.5rem', textAlign: 'right', left: '2.5rem', bottom: '4rem', transform: 'rotate(130deg)' }}>{query.toolTips.frontmatter.arrowleft}</p>
              </RedDot>
              <RedDot style={{ left: '79.5%', top: '66%', transform: 'rotate(-235deg)' }} onClick={() => { setRotation(18) }} arrow={ArrowRight}>
                <p style={{ width: '4.5rem', textAlign: 'left', bottom: '4rem', right: '2.5rem', transform: 'rotate(235deg)' }}>{query.toolTips.frontmatter.arrowright}</p>
              </RedDot>
              <RedDot style={{ left: '87%', top: '53%', backgroundColor: rotateDings === 0 ? '#df3242' : 'green' }} onClick={rotateDingsOnClick} >
                {rotateDings === 0 ? "OFF" : "ON"}
                <p style={{ width: '6rem', textAlign: 'left', bottom: '-2.5rem', left: '-1rem' }}>{query.toolTips.frontmatter.switch}</p>
              </RedDot>

              <RadioStationSignal style={{ opacity: showMask ? '0' : '1' }}>
                <div dangerouslySetInnerHTML={{ __html: radioText }} />
              </RadioStationSignal>
            </ICanNotThingOfOtherName>
            <div style={{ height: '100vh', zIndex: '10', paddingTop: '10%' }}>
              <RightColContent paragraphWidth="100%" body={query.all.body} smallerText toLeft />
            </div>
          </RadioBG>
          <ReactPlayer url={radioSound} playing={audioPlay} loop width={0} height={0} />
          {showMask &&
            <RadioFirstViewMask onClick={closeMask} ></RadioFirstViewMask>
          }
        </RadioBGContainer>

      </RadioContainer>
    </>
  );
};
export default Radio;
