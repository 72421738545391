import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import CipherWarAnimation from 'src/components/mobile/cipherWarAnimation/cipherWarAnimation';
import { Circle } from 'src/components/mobile/cipherWarAnimation/styles';
import map from 'src/assets/images/chapter_4/sub_10/desktop/map-pl.svg';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, title }) => (
  <BlockContainer height="1400px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <ChpaterTitle marginTop="20rem">{title}</ChpaterTitle>
      <TextAnimation containerStyle={{ height: 'auto', padding: '0% 7%' }} body={query.mdx.body} />
      <div style={{ position: 'relative' }}>
        <CipherWarAnimation background={map} />
        <Circle size="110vw" top="-22vw" right="-5vw" opacity="0.2" />
        <Circle size="75vw" top="-6vw" right="12.5vw" opacity="0.2" />
        <Circle size="50vw" top="6vw" right="25vw" opacity="0.2" />
        <Circle size="25vw" top="18vw" right="37.5vw" opacity="0.2" />
      </div>
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
