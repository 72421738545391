import styled, { css, keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

const slideUp = keyframes`
  from {
    opacity: 0;
    top: 70px;
  }
  to {
    opacity: 1;
    top: 0px;
  }
`;

const animationTop = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 500ms forwards
    `;
  }
  return null;
};
const animationBottom = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 0.8s cubic-bezier(0.78, 0, 0.235, 1) 800ms forwards
    `;
  }
  return null;
};
const animationTopSecondary = ({ visible }) => {
  if (visible) {
    return css`
      ${slideUp} 1.2s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards
    `;
  }
  return null;
};

export const ColumnContent = styled.div`
  height: 100%;
  margin-right: 2rem;
  padding-left: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  ${({ toLeft }) => toLeft && `
    align-items: flex-start;
  `}

  & > h2 {
    ${{ ...fonts.headline_1 }}
    ${{ ...colors.light }}
    text-align: right;
    position: relative;
    opacity: 0;
    top: 60px;

    animation: ${animationTop};
    ${({ smallerText }) => smallerText && `
      font-size: 2.8125rem;
    `}
  }

  & > h3 {
    ${{ ...fonts.headline_3a }}
    ${{ ...colors.light }}
    text-align: right;
    position: relative;
    opacity: 0;
    animation: ${animationTopSecondary};

  }

  & > p, & > div > p {
    ${{ ...fonts.paragraf }}
    ${{ ...colors.light }}
    text-align: right;
    max-width: ${(props) => (props.paragraphWidth ? props.paragraphWidth : '398px')};
    display: inline;
  }
  span {
    font-size: 1rem;
    line-height: 1.62rem;
    letter-spacing: 0.03rem;
  }
`;
export const Wrapper = styled.div`
  text-align: right;
  width: 22rem;
  max-zoom: 40%;
  position: relative;
  opacity: 0;
  animation: ${animationBottom};
  ${({ toLeft }) => toLeft && `
    text-align: left;
  `}
`;
