import styled, { keyframes } from 'styled-components';
import { fonts, colors } from '../../../../../globals';

export const RadioContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const RadioBGContainer = styled.div`
  width: 100vw;
  height: 100vh;
  transition: 0.5s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
 ${({ openModal }) => openModal && `
    transform: translateX(-20vw);
 `}
 ${({ backgroundImage }) => backgroundImage && `
    background-image: url(${backgroundImage});
`}

`;
export const RadioBG = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-left: 8vw;
`;

export const ICanNotThingOfOtherName = styled.div `
  display: flex;
  width: 72rem;
  height: 63.25rem;
  position: relative; 
`
export const ContactRadioContainer = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  ${({ backgroundImage }) => backgroundImage && `
    background-image: url(${backgroundImage});
  `}
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
`
export const RadioWindowPositioner = styled.div `
  position: absolute;
  ${({ isLeft }) => isLeft && `
    left: 22.2%;
    top: 36.5%;
  `}
  ${({ isRight }) => isRight && `
    right: 12.5%;
    top: 36%;
  `}
`

const arrowAnimation = keyframes`
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-10deg);
  }
  19% {
    transform: rotate(-23deg);
  }
  26% {
    transform: rotate(13deg);
  }
  56% {
    transform: rotate(-34deg);
  }
  70% {
    transform: rotate(45deg);
  }
  85% {
    transform: rotate(24deg);
  }
  90% {
    transform: rotate(26deg);
  }
  100% {
    transform: rotate(-45deg);
  }
`;

export const ArrowRadio = styled.div `
  height: 10.625rem;
  width: 0.875rem;
  position: absolute;
  left: 47%;
  top: 28%;
  animation: ${arrowAnimation} 2s infinite; 
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`
export const ArrowRadioRight = styled.div `
  height: 10.625rem;
  width: 0.875rem;
  position: absolute;
  left: 44%;
  top: 33%;
  animation: ${arrowAnimation} 1s 2s infinite; 
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`

export const RadioWindow = styled.div `
  width: 7.2rem;
  height: 4.9rem;
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`

export const RightShield = styled.div `
  width: 23.75rem;
  height: 23.75rem;
  position: absolute;
  top: 49.8%;
  left: 56.2%;
  transition: 0.2s;
  background-position: center;
  background-size: 100%;
  border-radius: 100%;
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`
export const LeftShield = styled.div `
  width: 23.75rem;
  height: 23.75rem;
  position: absolute;
  top: 50.8%;
  left: 20.8%;
  transition: 0.2s;
  background-position: center;
  background-size: 100%;
  border-radius: 100%;
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`

export const SwitchContainer = styled.div `
  width: 6.6875rem;
  height: 6.6875rem;
  position: absolute;
  top: 62.5%;
  left: 68.3%;
  transition: 0.2s;
  background-size: 100%;
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`

export const Switch2Container = styled.div `
  width: 5.125rem;
  height: 5.125rem;
  position: absolute;
  top: 51.5%;
  left: 79.3%;
  transition: 0.2s;
  background-size: 100%;
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`

export const SwitchCircle = styled.div `
  width: 8rem;
  height: 8rem;
  position: absolute;
  border-radius: 100%;
  border: 1px solid #fff;
  top: -0.65625rem;
  left: -0.65625rem;
  z-index: 10; 
`
export const SwitchCircleDot = styled.div `
  width: 0.7rem;
  height: 0.7rem;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`

export const RedDot = styled.div `
  width: 2.9375rem;
  height: 2.9375rem;
  background-color: #df3242;
  border-radius: 100%;
  position: absolute;
  cursor: pointer;
  font-family: Red Hat Display,Open Sans;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 10;
  ${({ arrow }) => arrow && `
  background-image: url(${arrow});
`}
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  p {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75rem;
    position: absolute;
  }
`

export const AllSiteMask = styled.div `
  width: 100vw;
  heigth: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  ${({ backgroundImage }) => backgroundImage && `
  background-image: url(${backgroundImage});
`}
`

const toolTipAnim = keyframes`
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const ToolTip = styled.div`
  width: 22rem;
  height: 7rem;
  background-color: #f6f5ef;
  color: #302a29;
  border-radius: 0.25rem;
  position: absolute;
  top: 34.5%;
  left: 57.3%;
  font-family: Red Hat Display,Open Sans;
  font-size: 0.875rem;
  line-height: 1.2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: center;
  transition: 0.5s;
  animation: ${toolTipAnim} 1s cubic-bezier(0.820, 0.000, 0.195, 1.000) forwards;
  z-index: 10;
  font-weight: 500;
  ${({ clickedArrow }) => clickedArrow && `
    display: none;
 `};
  &:after {
    position: absolute;
    bottom: -1.6rem;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-top: 2.2rem solid #f6f5ef;
  }
  span {
    color: #d02c33;
  }
`;

export const RadioStationSignal = styled.div `
  position: absolute;
  width: 17rem;
  height: 14rem;
  background-color: rgba(55, 49, 55, 0.7);
  top: 58%;
  left: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #989494;
  border-radius: 0.25rem;
  color: #fff;
  font-family: Red Hat Display,Open Sans;
  padding: 1rem;
  font-size: 1rem;
`

export const RadioFirstViewMask = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
`