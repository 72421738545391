import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, preTitle }) => (
  <BlockContainer height="1250px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo" wrapperStyle={{ background: '#22232e' }}>
      {/* <ChpaterTitle marginTop="8rem">{preTitle}</ChpaterTitle> */}
      <TextAnimation containerStyle={{ height: '70%', marginTop: '1rem' }} body={query.mdx.body} dark />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
