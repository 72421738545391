import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Radio from 'src/components/desktop/radio/radio.jsx';
import { SlideLarge } from '../../_styles';
const Slide = ({ query }) => {
  const images = useStaticQuery(graphql`
      query {
        foreground: file(relativePath: { eq: "chapter_4/sub_10/comb.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        background: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-background.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        leftWindow: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-lewe.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        overlay: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-overlay_radyjko.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        switch: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-pokretlo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        rightWindow: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-prawe_gorne_okienko.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        switch2: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-przelacznik.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        radio: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-radyjko.png" }) {
          childImageSharp {
            fluid(maxWidth: 1160) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        shield: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-tarcza.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pointer: file(relativePath: { eq: "chapter_4/sub_10/4-10-3-wskazowka.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      `);
  return (
    <SlideLarge>
      <Radio
        query={query}
        bgPhoto={images.background.childImageSharp.fluid.src}
        radio={images.radio.childImageSharp.fluid.src}
        leftWindow={images.leftWindow.childImageSharp.fluid.src}
        rightWindow={images.rightWindow.childImageSharp.fluid.src}
        shield={images.shield.childImageSharp.fluid.src}
        mainSwitch={images.switch.childImageSharp.fluid.src}
        mainSwitch2={images.switch2.childImageSharp.fluid.src}
        arrow={images.pointer.childImageSharp.fluid.src}
        allSiteMask={images.overlay.childImageSharp.fluid.src}
      />
    </SlideLarge>

  );
};


export default Slide;
