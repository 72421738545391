import React from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import CipherWarAnimation from 'src/components/desktop/cipherWarAnimation/cipherWarAnimation';
import { Circle, Lines } from 'src/components/desktop/cipherWarAnimation/styles';
import { useStaticQuery, graphql } from 'gatsby';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_10/desktop/1-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
    modal: file(relativePath: { eq: "chapter_4/sub_10/4-10-1-modal.jpg"}) {
      childImageSharp {
        fixed(width: 506, height: 762)  {
          ...GatsbyImageSharpFixed_withWebp_noBase64
          width
        }
      }
    }
  }
`);
  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          background={{ image: assetQuery.mainscene.childImageSharp.fixed }}
        />
        <Mordo
          scene={2}
          move={{ x: -20, y: -10 }}
          background={{ color: 'transparent' }}
          flexi={{ column: true, alignItems: 'flex-end' }}
        >
          <Lines />
        </Mordo>
        <Mordo
          scene={8}
          move={{ x: -10, y: -5 }}
          background={{ color: 'transparent' }}
          flexi={{ column: true, alignItems: 'flex-end' }}
        >
          <CipherWarAnimation />
        </Mordo>
        <Mordo
          scene={4}
          move={{ x: -15, y: -20 }}
          background={{ color: 'transparent' }}
        >
          <Circle size="20vw" top="17.5vw" right="30vw" />
        </Mordo>
        <Mordo
          scene={5}
          background={{ color: 'transparent' }}
          move={{ x: -27.5, y: -32.5 }}
        >
          <Circle size="30vw" top="12.5vw" right="25vw" opacity="0.4" />
        </Mordo>
        <Mordo
          scene={6}
          background={{ color: 'transparent' }}
          move={{ x: -40, y: -45 }}
        >
          <Circle size="42.5vw" top="6.5vw" right="18.5vw" opacity="0.2" />
        </Mordo>
        <Mordo
          scene={6}
          background={{ color: 'transparent' }}
          move={{ x: -52.5, y: -57.5 }}
        >
          <Circle size="55vw" top="0" right="12.5vw" opacity="0.1" />
        </Mordo>
        <Mordo
          scene={9}
          move={{ x: -5, y: -10 }}
        >
          <LeftColContent body={query.mdx.body} images={[assetQuery.modal.childImageSharp.fixed]} />
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>
  );
};


export default Slide;
