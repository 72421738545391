import React, { useEffect, createRef } from 'react';
import lottie from 'lottie-web';
import { AnimationContainer, Background, MapWrapper } from './styles';
import animation from './data.json';

const CipherWarAnimation = ({ background }) => {
  const animationContainer = createRef();

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      // path: '/assets/json/chapter_1/sub_3/data.json',
      animationData: animation,
    });
    setTimeout(() => { anim.play(); }, 3000);
    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <MapWrapper>
      <Background
        background={background}
      />
      <AnimationContainer ref={animationContainer} />
    </MapWrapper>
  );
};

export default CipherWarAnimation;
