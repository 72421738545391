import styled, { css } from 'styled-components';

export const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    left: 3px;
    ${({ background }) => background
        && css`
    background-image:url(${background || ''});
    `}
    background-repeat: no-repeat;
`;

export const MapWrapper = styled.div`
  position: relative;
  width: 50vw;
  margin-right: 15vw;
  margin-top: 25vh;
`;

export const AnimationContainer = styled.div`
  display: block;
  position: relative;
  max-width: 100%;
`;

export const Circle = styled.div`
border: 1px solid #9caebc;
opacity: ${(props) => (props.opacity ? `${props.opacity}` : '0.5')};
border-radius: 50%;
width: ${(props) => (props.size ? `${props.size}` : '20vw')};
height: ${(props) => (props.size ? `${props.size}` : '20vw')};
position: absolute;
right: ${(props) => (props.right ? `${props.right}` : '20vw')};
top: ${(props) => (props.top ? `${props.top}` : '20vw')};
`;

export const Lines = styled.div`
background-size: 8vw;
  background-image:
    linear-gradient(to right, #faf4f1 1px, transparent 1px);
  opacity: 0.1;
  position: absolute;
  width: 55%;
  height: 100%;
`;
