import React, { useState } from 'react';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import BackgroundImage from 'gatsby-background-image';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import arrowSVG from '../../../assets/images/chapter_4/sub_10/arrow.svg';
import {
  CombContainer, CombBG, CombGameContainer, CombGameTitle, CombGameNumbers,
  CombPerSeContainer, CombSizer, MainArrowContainer, ArrowContainer, ArrowInnerContainer,
  ArrowIcon, ArrowText, CombGameNumberContainer,
} from './styles';
import { getLangFromPath } from 'src/utils/index.js';

const Comb = ({
  texts,
  combTitle,
  arrowTitle,
  images,
}) => {
  const [combPosition, setCombPosition] = useState(-7.3);
  const newCombPosition = () => {
    if (combPosition > -16.3) {
      setCombPosition(combPosition - 3.5);
    } else {
      setCombPosition(-17.7);
    }
  };

  const newCombPosition2 = () => {
    if (combPosition < 16.7) {
      setCombPosition(combPosition + 3.5);
    } else {
      setCombPosition(17.7);
    }
  }
  return (
    <>
      <CombContainer>
        <CombBG bg={images.background.childImageSharp.fluid.src}>
          <LeftColContent smallerTitle body={texts} style={{ marginLeft: '7vw', maxWidth: '47vw' }} />
          <CombGameContainer>
            <CombGameTitle lang={getLangFromPath() === 'UA' ? 'true' : ''}>
              <MDXProvider components={{
                p: (props) => <p {...props} tabIndex="0" />,
              }}
              >
                <MDXRenderer>{combTitle}</MDXRenderer>
              </MDXProvider>
            </CombGameTitle>
            <CombGameNumbers>
              <div style={{ width: '100%', position: 'relative', overflow: 'hidden' }}>
                <CombGameNumberContainer position={combPosition}>
                  254354671<span style={{ color: combPosition === -17.7 ? '#d7383e' : '#4f4a47' }}>01</span>45
                    <span style={{ color: combPosition === -17.7 ? '#d7383e' : '#4f4a47' }}>01</span>45
                    <span style={{ color: combPosition === -17.7 ? '#d7383e' : '#4f4a47' }}>01</span>2767492843298
                  </CombGameNumberContainer>
              </div>
              <CombPerSeContainer>
                <ParallaxMordo showOutSite>
                  <Mordo
                    scene={1}
                    move={{ x: 15, y: 15 }}
                  >
                    <BackgroundImage fluid={images.foreground.childImageSharp.fluid}>
                      <CombSizer />
                    </BackgroundImage>
                  </Mordo>
                </ParallaxMordo>
              </CombPerSeContainer>
              <MainArrowContainer>
                <ParallaxMordo showOutSite>
                  <Mordo
                    scene={2}
                    move={{ x: 5, y: 5 }}
                  >
                    <ArrowContainer onClick={newCombPosition}>
                      <ArrowInnerContainer>
                        <ArrowIcon image={arrowSVG} style={{ transform: 'rotate(0deg)' }} />
                      </ArrowInnerContainer>
                      <ArrowText>
                        {arrowTitle}
                      </ArrowText>
                    </ArrowContainer>
                  </Mordo>
                  <Mordo
                    scene={2}
                    move={{ x: 5, y: 5 }}
                  >
                    <ArrowContainer onClick={newCombPosition2} style={{ top: '27rem', left: '20rem', marginLeft: '2rem' }}>
                      <ArrowInnerContainer>
                        <ArrowIcon image={arrowSVG} style={{ transform: 'rotate(180deg)' }} />
                      </ArrowInnerContainer>
                      <ArrowText>
                        {arrowTitle}
                      </ArrowText>
                    </ArrowContainer>
                  </Mordo>
                </ParallaxMordo>
              </MainArrowContainer>
            </CombGameNumbers>
          </CombGameContainer>
        </CombBG>
      </CombContainer>
    </>
  );
};
export default Comb;
