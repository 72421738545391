import React from 'react';
import { useInView } from 'react-intersection-observer';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Hyperlink from 'src/components/_shared/hyperlink/hyperlink';
import { ColumnContent, Wrapper } from './styles';

const RightColontent = ({ body, paragraphWidth, smallerText, toLeft }) => {
  const [ref, inView] = useInView({
    threshold: 0.8,
  });
  return (
    <ColumnContent ref={ref} visible={inView} paragraphWidth={paragraphWidth} smallerText={smallerText} toLeft={toLeft}>
      <MDXProvider components={{
        Wrapper,
        Hyperlink,
        p: (props) => <p {...props} tabIndex="0" />,
      }}
      >
        <MDXRenderer visible={inView}>{body}</MDXRenderer>
      </MDXProvider>
    </ColumnContent>
  );
};

export default RightColontent;
