import styled from 'styled-components';

export const CombContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;  
`;

export const CombBG = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  ${({ bg }) => bg && `
    background-image: url(${bg});
  `}
`;

export const CombGameContainer = styled.div`
  position: absolute;
  right: 0;
  width: 49vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CombGameTitle = styled.div`
  margin-top: 25vh;
  margin-bottom: 5vh;
  color: #4f4a47;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 400; 
  max-width: 54%;
  line-height: 1.5;
  font-family: "Abril Fatface";
  h2 {
    font-family: "Abril Fatface", Prata;
    font-weight: 400;
    line-height: 1.5;
    max-width: 18rem;
  }
  ${({ lang }) => lang === 'true' && `
  max-width: 100%;
  h2 {
    max-width: 50rem;
  }
`}
  span {
    color: #d7383e;
  }
`;

export const CombGameNumbers = styled.div`
  font-family: Abril Fatface,Prata;
  font-weight: 400;
  font-size: 5.5rem;
  color: #4f4a47;
  width: 100%;
  padding-left: 2rem;
  margin-top: 10vh;
  position: relative;
  letter-spacing: 0.6rem;
  white-space: nowrap;
`;

export const CombGameNumberContainer = styled.div`
  left: ${(props) => props.position}rem;
  position: relative;
  font-size: 5.5rem;
  color: #4f4a47;
  letter-spacing: 0.6rem;
  white-space: nowrap;
`
export const CombPerSeContainer = styled.div`
  position: absolute;
  transition: 0.3s;
  left: 7.7rem;
  top: -4rem;
`;
export const CombSizer = styled.div`
  width: 95rem;
  height: 25rem;
`;

export const MainArrowContainer = styled.div`
  position: absolute;
  left: 30rem;
  top: 14rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ArrowContainer = styled.div`
  border-radius: 100%;
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(67, 66, 65, 0.35);
  cursor: pointer;
  z-index: 20;
`;
export const ArrowInnerContainer = styled.div`
  border-radius: 100%;
  width: 4.75rem;
  height: 4.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #faf4f1;
`;
export const ArrowIcon = styled.div`
  width: 2.625rem;
  height: 1.875rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url(${(props) => props.image})
`;

export const ArrowText = styled.div`
  font-family: "Red Hat Display", sans-serif;
  color: #faf4f1;
  text-align: center;
  position: absolute;
  font-size: 0.9rem;
  bottom: -3rem;
  left: 0;
  line-height: 1.5;
  width: 5rem;
  letter-spacing: 1px;
`;
